import { BaseAPI } from 'api/auth';
import { PageableResponse } from 'api/interfaces/responses';
import { ReportDto, ReportQueryProps, ReportSummaryDto } from 'features/Reports/types';
import { PageableParams } from 'hooks/useReactQueryHelpers';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';

export const fetchReportSummary = (params: PageableParams) => {
  return BaseAPI.get<PageableResponse<ReportSummaryDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/summary/?reportEntityType=TABLEAU&page=${params.page}&size=${params.size}`,
  );
};

export const fetchReports = (
  { reportNumber, startPeriod, endPeriod }: ReportQueryProps,
  params: PageableParams,
) => {
  return BaseAPI.get<PageableResponse<ReportDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/?reportEntityType=TABLEAU&number=${reportNumber}&startPeriod=${startPeriod}&endPeriod=${endPeriod}&page=${params.page}&size=${params.size}`,
  );
};
