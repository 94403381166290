import { useForm } from 'react-hook-form';
import { useGetCaseGeneralInfoByUUID } from '../../GeneralInfo/GeneralInfoQuery';
import { DynamicField, DynamicValue } from 'components/Dynamic';
import { useEffect } from 'react';

export function useMileagesForm(caseUUID: string) {
  const { data: caseGeneralInfoData } = useGetCaseGeneralInfoByUUID(caseUUID, true);

  const mileagesHookForm = useForm({
    defaultValues: {
      mileageOverride: caseGeneralInfoData?.mileageOverride,
      totalMileage: caseGeneralInfoData?.totalMileage,
    },
  });

  useEffect(() => {
    if (caseGeneralInfoData) {
      mileagesHookForm.reset({
        mileageOverride: caseGeneralInfoData.mileageOverride,
        totalMileage: caseGeneralInfoData.totalMileage,
      });
    }
  }, [caseGeneralInfoData, mileagesHookForm]);

  const mileagesFields: DynamicField<DynamicValue>[] = [
    {
      name: 'mileageOverride',
      label: 'Override/Overage Mileage',
      colspan: 3,
      isReadOnly: true,
    },
    {
      label: 'Total Mileage',
      name: 'totalMileage',
      colspan: 3,
      isReadOnly: true,
    },
  ];

  return {
    mileagesHookForm,
    mileagesFields,
  };
}
